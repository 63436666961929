import React, { useRef,useEffect } from 'react';

const FeatureCard = ({ data,onClickTransition,transition }) => {
 
    const cardRef = useRef();
    const { icon, facility, inShort,readMore,id } = data;
    
    const handleTransition = (e) => {
      const parentNode = cardRef.current;
      const children = cardRef.current.children;
     
      for(let i = 0; i < children.length; i++){
        if(e.type ==='mouseover'){
          children[i].style.color = '#fff';
          parentNode.style.background='#15165f';
        }else if(e.type === 'mouseout'){
          children[i].style.color = '';
          parentNode.style.background='linear-gradient(135deg,#e3e8ec,#e3e9ed)';
        }
      }
   
  }
  useEffect(()=>{
    const parentNode = cardRef.current;
    const children = cardRef.current.children;
    if(transition === id){
      // alert(transition)
      parentNode.style.background='#15165f';
        
      for(let i = 0; i < children.length; i++){
          children[i].style.color = '#fff';
          
      }
    }else{
      parentNode.style.background='linear-gradient(135deg,#e3e8ec,#e3e9ed)';
      for(let i = 0; i < children.length; i++){
        children[i].style.color = '';
    }
    }

  },[transition,id])
    useEffect(()=>{
      
      const parentNode = cardRef.current;
    if(window.matchMedia("(pointer: coarse)").matches ){
      parentNode.removeEventListener("mouseover", handleTransition, false);
      parentNode.removeEventListener("mouseout", handleTransition, false);
    }else{
      // parentNode.addEventListener("click", handleTransition, false);
      parentNode.addEventListener("mouseover", handleTransition, false);
      parentNode.addEventListener("mouseout", handleTransition, false);
    }
   
      
  
     
        },[])
  return (
    <div className='feature-card' ref={ cardRef } {...(window.matchMedia("(pointer: coarse)").matches && {onClick :()=>{onClickTransition(id)}})} >
    <span className='feature-icon'>
    <i className = { icon }></i>
    </span>
    <h3 className='title'>{ facility }</h3>
    <p className='f-16'>{ inShort }</p>
     <a  href={readMore?readMore:"/"} target ="_blank" rel="noreferrer noopener">read more</a>
</div> 

  );
};

export default FeatureCard;
