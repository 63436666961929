import React from 'react';

const PortCard = ({ children, data }) => {
  const { where, title, overview ,location} = data;
  return (
      <article className='port-card' loading="lazy">
             <div className='port-img'>
                 {children}
             </div>
             <div className='content'>
                <p className='plateform'>{where}</p>
                <h3 className='title'>
                  <a href ={location} target = '_blank' rel="noreferrer">{ title }</a>
                </h3>
                <p className='port-info'>{ overview }</p>
               
             </div>
             <a href ={location}  target='_blank' rel="noreferrer" className='port-link'><svg xmlns="http://www.w3.org/2000/svg"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
</svg></a>
      </article>
  );
};

export default PortCard;
