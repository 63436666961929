// import React from 'react';
import ReactDOM from 'react-dom';

const FormPortal = ({ children }) => {
  return (
    ReactDOM.createPortal(
        children,
        document.getElementById("model"))
  )
}

export default FormPortal
