import React from 'react';
import Details from '../components/about-page/Details';
import { ABOUT } from '../data';
import avatarImg from '../components/home/img/2.png';

const About = () => {
  const {author_name:{title, subTitle},about_datails} = ABOUT;
  const renderAboutDetails = about_datails.map(
    (details,id) => {
        return (
          <Details
            data = { details } 
            key = {id}
          />
        );
  });
  return (
    <>
    <div className='about'>
    <h1 className='author-name'>
      <span>{title}</span>
      <span>{ subTitle }</span>
    </h1>
     <div className='author-img'>
        <img src={avatarImg} alt='' />
     </div>
    
    </div>
    <div className='about-details'>
  <h2 className='title'>Hi, I'm Akanksha</h2>
  <p className='inshort'>I’m an Engineer in Aeronautics & Entrepreneur student by Academically. I’ve a keen interest in Graphic Design, which makes Akanksha a Graphic Designer too. I also have a decent grip & curiosity in Onboarding Designing, Characters and Video Making.</p>
  <img src='./assets/img/signature.png' alt='' />
</div>
<div className='about-cards'>
<h2 className='about-title2'>My Real life Social Expercience</h2>

{ renderAboutDetails }
</div>
    </>);
};

export default About;
