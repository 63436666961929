import React from 'react';
import useDocumentTitle from '../custom-hooks/useDocumentTitle';
import Work from './Work';
// following loop function import all images from img folder
function importAll(r) {
  let images = {};
   r.keys().forEach((item) => { images[item.replace('./', '')] = r(item); });
  return images
 }
 const images = importAll(require.context('./img', false, /\.(png|jpe?g|svg)$/));
// console.log(images);
const splash_data = [
 {
  id:'splash1', 
  title:'Splash Screen For Laundry App',
  inShort:'Splash screen for laundry at your doorsteps designed by Akanksha Freelance Graphic designer. Its a perfect android app template and iOS app template for a laundry app.',
  splash_img : [
    {id:'img1',imgURL:images['1.png']},
    {id:'img2',imgURL:images['2.png']},
    {id:'img3',imgURL:images['3.png']},
    {id:'img4',imgURL:images['4.png']}
  ],
  clasN:'grid__col--4'
},
{
  id:'splash2', 
  title:'Splash Screen For Medical App',
  inShort:`Healthcare App Splash Screen designed by Akanksha freelance graphic designer. The app would serve as a multi purpose solution to individuals as their number one health companion. This medical splash screen will hold on your user’s attention and will create first perception about the app. 
  `,
  splash_img : [
    {id:'medical-1.png',imgURL:images['medical-1.png']},
    {id:'medical-2.png',imgURL:images['medical-2.png']},
    {id:'medical-3.png',imgURL:images['medical-3.png']},
    {id:'medical-4.png',imgURL:images['medical-4.png']}
  ],
  clasN:'grid__col--4'
},
{
  id:'splash3', 
  title:'Splash Screen For laundry service app ',
  inShort:`Online laundry services make the procedure of washing convenient and fast. The splash screen, I designed for laundry service app, here the first thing that strikes an eye here is that the illustrations are so simple that user may be eager to try the app without reading an instruction manual.
  `,
  splash_img : [
    {id:'service-app-1',imgURL:images['service-app-1.png']},
    {id:'img2-service',imgURL:images['service-app-2.png']},
    {id:'service-img3',imgURL:images['service-app-3.png']},
    {id:'serve-img4',imgURL:images['service-app-4.png']}
  ],
  clasN:'grid__col--4'
},
{  id:'splash4', 
title:'Splash Screen For Food App',
inShort:`This Food delivery app splash screen for mobile app. Onboarding UI designed by Akankhsa Freelance Graphic Designer. 
`,
splash_img : [
  {id:'food-img1',imgURL:images['food-1.png']},
  {id:'food-img2',imgURL:images['food-2.png']},
  {id:'food-img3',imgURL:images['food-3.png']},
],
clasN:'grid__col--3'
},
{  id:'splash5', 
title:'Splash Screen For Restaurant App',
inShort:`Being as a freelance graphic designer, It’s very tough challenge to get a client. Following are the splash screen for mobile app for a restaurant app. I created this onboarding Illustration for my first client on Fiverr.
`,
splash_img : [
  {id:'restaurant-img1',imgURL:images['restaurant-1.png']},
  {id:'restaurant-img2',imgURL:images['restaurant-2.png']},
  {id:'reataurant-img3',imgURL:images['restaurant-3.png']},
  {id:'reataurant-img4',imgURL:images['restaurant-4.png']}
],
clasN:'grid__col--4'
},
{
  id:'splash6',
  title:`Splash Screen For Tutoring App`,
  inShort:`Are you developing an app for tutoring and looking for splash screen for mobile app for tutoring. Last time few month I designed some splash screen for tutoring app. Have a look!!
  `,
  splash_img : [
    {id:'tutoral-img1',imgURL:images['tutorial-1.png']},
    {id:'tit-img2',imgURL:images['tutorial-2.png']},
    {id:'totoria-img3',imgURL:images['tutorial-3.png']},
  ],
  clasN:'grid__col--3'
},
{  id:'splash7', 
title:'Splash Screen For Cab Booking App',
inShort:`Taxi App Welcome Screens for cab booking. User is searching a cab and as he installed cab booking app first time. He gets a splash screen for mobile app which actually help users implicitly or explicitly understand how the app can be used in their lives
`,
splash_img : [
  {id:'cab-img1',imgURL:images['cab-booking-1.png']},
  {id:'cab-img2',imgURL:images['cab-booking-2.png']},
  {id:'cab-img3',imgURL:images['cab-booking-3.png']},
],
clasN:'grid__col--3'
},
{  id:'splash8', 
title:'Splash Screen For an Auction App',
inShort:`This is splash screen before first time login in an auction app. Auctions submitted by other which are available for bidding at time user logs into.
`,
splash_img : [
  {id:'aucation-img1',imgURL:images['aucation-1.png']},
  {id:'aucation-img2',imgURL:images['aucation-2.png']},
  {id:'auation-img3',imgURL:images['aucation-3.png']},
],
clasN:'grid__col--3'
},

]
const SplashScreen = () => {
    useDocumentTitle("Splash Screen for Mobile App Akanksha Grafic Designer")
    
      const splash_container = splash_data.map(
        ( data,id ) => {
          const {title, inShort, splash_img } = data;
          return(
<div className='splashScreen' key={title + id}>
      <div className='splash-header'>
          <h2>{title}</h2>
          <p>{inShort}</p>
      </div>
      <div className={data.clasN}>
         { splash_img.map(
      (img) => {
        return(
          <div className='splash-card' key = { img.id }>
              <img src= {img.imgURL} className='splash-card-img' alt='dfhgk' />
          </div>
        )
      }) }
      </div>
 </div>
          )
        
      })
   
  return (
 <Work>
 <div className='splashScreen'>
     <h1 className='splash-heading'>Onboarding Illustrations</h1>
     <p className='splash-title'>Onboarding is a virtual unboxing experience that helps users get started with an app. Onboarding welcome users and excite them about the experience ahead. Drive users to take actions that increase engagement and retention in the first seven days
</p>
 </div>
    { splash_container }
 </Work>
    
  )
}

export default SplashScreen;