import React from 'react'

const Form = () => {
  return (
      <div>
    <form name='contact' className='cForm'  method='post'>
    <input type="hidden" name="form-name" value="contact" />
            <input autoComplete='off' className='cInput' type='text' placeholder='Your Name' name = "user_name"spellCheck = "false" required />
            <input autoComplete='off' className='cInput' type='email' name="email" placeholder='Email' required />
            <input autoComplete='off' className='cInput' type='tel' name='phone-number' placeholder=' Number' required />
            <textarea className='cTextarea' name='message' placeholder='Write your message' required />
            <button type ="submit" className='c-btn'>Send Message</button>
    </form>
   
    </div>
  )
}

export default Form