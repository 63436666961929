import React from 'react';
import ContactInfo from '../components/contact-info-and-form/ContactInfo';
import Form from '../components/contact-info-and-form/Form';

const Contact = () => {
  return (
    <div className='contact'>
        <ContactInfo />
        <Form />
    </div>);
};

export default Contact;
