import React, { memo, useEffect, useRef, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';

const Header = () => {
  const [left_0,setLeft_0] = useState(false);
  const navRef = useRef();
  const show_nav = left_0 === true ?'left-0':'';
  const changeIcon = left_0 === true ?'times':'bars';
      // This styling will be applied to a <NavLink> when the
  // route that it links to is currently selected.
  let activeStyle = {
    color:'#ff014f'
  };
useEffect(() => {
 window.addEventListener('scroll',() => {
  if (window.scrollY > 60 && window.scrollY < 500  ) {
    navRef.current.style.top = '-74px'
    navRef.current.style.boxShadow = 'none'
   
// console.log('4000');
  } else if(window.scrollY > 600 || window.scrollY < 60  ) {
    navRef.current.style.top = '0'
  }else if(window.scrollY > 500){
    navRef.current.style.boxShadow= '0 0 4px 2px rgba(0, 0, 0, 0.158)'
  }

 })
},[])
  return (
    <header className='main-header' ref = {navRef} id = "hom" >
     <nav className='main-nav'>
        <div className='logo'>
           <Link to = '/'>
               <img src='./assets/img/akanksha-graphic-designer2-45x44.png' alt='akanksha graphic logo designer' />
               <span>
                     Akanksha
               </span>
           </Link>
        </div>
        <div className={`nav-container ${show_nav}`}>
        <ul className='nav-list'>
            <li>
                <NavLink onClick={() => setLeft_0(!left_0)} to={"/"}  style={({ isActive }) =>
              isActive ? activeStyle : undefined
            }>home</NavLink>
            </li>
            <li>
                <NavLink onClick={() => setLeft_0(!left_0)} to={"/akanksha-graphic-designer"} style={({ isActive }) =>
              isActive ? activeStyle : undefined
            }>about</NavLink>
            </li>
            <li className='main-dropdown'>
               <NavLink  to={{hash:'#'}} style={({ isActive }) =>
              isActive ? activeStyle : undefined
            }>my work <i className ="fas fa-chevron-down"></i></NavLink>
             <div className='dropdown'>
               <div>
                 <NavLink onClick={() => setLeft_0(!left_0)} to ={'/Onboarding-Splash-Screen-for-Mobile-App'}>Splash Screens</NavLink>
                 <NavLink onClick={() => setLeft_0(!left_0)} to ={'/mobile-app-login-signup-page-design'}>Login Signup Design</NavLink>
               </div>
                </div>
            </li>
            <li className='main-dropdown'>
                <NavLink to={{hash:'#'}} >social presence <i className ="fas fa-chevron-down"></i></NavLink> 
                <div className='dropdown'>
                <div>
                 <a onClick={() => setLeft_0(!left_0)} href='https://dribbble.com/akkuvanshi' target='_blank' rel="noreferrer" >dribbble</a>
                 <a onClick={() => setLeft_0(!left_0)} href='https://www.behance.net/aakanksyaduvan'target='_blank' rel="noreferrer">behance</a>
                 <a onClick={() => setLeft_0(!left_0)} href='https://www.instagram.com/aakankshayaduvanshi/' target='_blank' rel="noreferrer">instagram</a>
                </div>
                </div>
            </li>
            <li>
                <NavLink onClick={() => setLeft_0(!left_0)} to={{pathname:'/contact'}}>contact</NavLink>
            </li>
        </ul>
        </div>
        
        <button className='main-nav-btn' onClick={() => setLeft_0(!left_0)} ><i className={'fas fa-' + changeIcon}></i></button>
     </nav>
    

    </header>);
};

export default memo(Header);
