import React from 'react';

const Work = ({children}) => {
  return (
    <div className='my-work'>
       { children }
    </div>);
};

export default Work;
