import React from 'react'

const ContactInfo = () => {
  return (
    <div className='contact-info'>
        <h2 className='title'>
        Let's discuss your idea !
        </h2>
        <p className='subTitle'> Thanks for reaching out to me. I am happy to help you achieve your goals and dreams.</p>
        <img className='contact-img' src='./assets/img/contact.png' alt='contact illustration' />
    </div>
  )
}

export default ContactInfo;