function importAllImages(r) {
    let images = {};
     r.keys().forEach((item) => { images[item.replace('./', '')] = r(item); });
    return images
   }
//    following importAllImages function  invokes for about page images
   const images_about = importAllImages(require.context('../pages/img/about', false, /\.(png|jpe?g|svg)$/));
//   console.log(images_about["1.png"]);

export const WHAT_I_DO = {
    facilities:[
        {   id:'f1',
            icon:'fas fa-globe',
            facility:"Website Design",
            inShort:'I create a beautiful website design that people love to engage with.',
            readMore:'https://www.fiverr.com/share/rrQ7mb'
        },
        {
            id:'f2',
            icon:'fas fa-mobile-alt',
            facility:"App Design",
            inShort:" I design high quality modern APP UX UI & Onboarding Splash screens.",
            readMore:'https://www.fiverr.com/share/4l0Gxk'
        },
        {
            id:'f3',
            icon:'fas fa-hashtag',
            facility:"Social Media Design",
            inShort:' Love to design creative social media post,banner & Ads design '
        },
        {
            id:'f4',
            icon:'fas fa-user-astronaut',
            facility:"Illustration Design",
            inShort:" Passionate to design meaningful Flat Character Illustrations for Web/App.",
            readMore:'https://www.fiverr.com/share/rrQ7mb'
        },
        {
            id:'f6',
            icon:'fas fa-palette',
            facility:"Icon Design",
            inShort:'Design custom icon for web/app and other digital media. ',
            readMore:'https://iconscout.com/contributors/akku'
        },
        {
            id:'f7',
            icon:'fas fa-dragon',
            facility:"Game Character Design",
            inShort:"Currently design Characters for gaming apps."
        }
        
        
    ]
}
export const PORTFOLIO = {
    portfolio:[
           {   
               where:'FIGMA',
               title:'Onboarding Mobile App Illustrations',
               overview:"I want to share with you some On boarding screens of an app. I’m working for a US client. They will help you to find the best restaurant near by you on the basis of your known one recommendation, is that fantastic?",
               link:"/",
               Children:<a href='/'><img src='https://cdn.dribbble.com/users/5166438/screenshots/14267157/media/4adb0581bc0713a94321048a742e19bd.png?compress=1&resize=1600x1200&vertical=top'alt='' />
               </a>,
               location:'/Onboarding-Splash-Screen-for-Mobile-App'

           },
           {   
            where:'FIGMA',
            title:'Animated Promo video for YoursOwn',
            overview:"This is promo video for Mobile App for Campus management. I was really excited about this project since I had a lot of freedom to play with the animation and different characters to make the experience special.",
            link:"/",
            Children:<iframe width="100%" height = "100%"  src="https://www.youtube.com/embed/QTuboZMVMjc" title="YouTube video player" frameBorder ="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>,
            location:'/'


        },
        {   
            where:'FIGMA',
            title:'Flat Character Illustrations For App / Web',
            overview:"I designed Flat Character Illustrations for a Canada client. For Talent hiring company. Where they took interviews on the basis of company requirements and provide the best candidate.",
            link:"/",
            Children:<a href='/'><img src='https://cdn.dribbble.com/users/5166438/screenshots/15714334/media/9b9feaebfa80148c42c6a743257d23fb.png?compress=1&resize=1600x1200&vertical=top'alt='' />
               </a>,
                              location:'/'


        },
        {   
            where:'FIGMA',
            title:'Onboarding Splash Screen for Mobile App',
            overview:"Onboarding is a virtual unboxing experience that helps users get started with an app. Onboarding welcome users and excite them about the experience ahead. Drive users to take actions that increase engagement.",
            link:"/",
            Children:<a href='/'><img src='https://cdn.dribbble.com/users/5166438/screenshots/13960035/media/a1ceb25a48e5c55be1e36a56bbf07f89.png?compress=1&resize=1600x1200&vertical=top'alt='' />
            </a>,
                           location:'/Onboarding-Splash-Screen-for-Mobile-App'


        },
        {   
            where:'FIGMA',
            title:'Mobile App Login Signup Page Design',
            overview:"I want to share with you some On boarding screens of an app. I’m working for a US client. They will help you to find the best restaurant near by you on the basis of your known one recommendation, is that fantastic?",
            link:"/",
            Children:<a href='/'><img src='https://cdn.dribbble.com/users/5166438/screenshots/16694010/media/34a12447ba152f9788640559715a04bd.png?compress=1&resize=1600x1200&vertical=top'alt='' />
            </a>,
            location:'/mobile-app-login-signup-page-design'


        },
        {   
         where:'FIGMA',
         title:'Design or Redesign your Web or App UX UI ',
         overview:"I’ll convert your idea into a digital masterpiece. You’ll get an outstanding UX/UI design! Please reach out with your project details and requirements so we can discuss your project before placing your order. ",
         link:"/",
         Children:<a href='/'><img src='https://mir-s3-cdn-cf.behance.net/project_modules/fs/e5dec8126552407.612fa4d06abd7.png'alt='' />
         </a>,
                        location:'/'


     },
     
    ]
}
export const ABOUT = {
    author_name:{
        title:'About Me',
        subTitle:'Graphic Designer | Illustrator | Video Editor'
    },
   about_datails:[
       {
           title:'SPOKE TO 100+ DIRECTORS & 1000+ TEACHERS OF HIGHER EDUCATION INSTITUTIONS',
           inShort:'Including Engineering, Arts, Management, government and private Indian institutions in just one years',
           img:images_about["1.png"]

       },
       {
           title:`
           INTERACTED WITH 5000+ GRADUATE STUDENTS`,
           inShort:'While interacting students with their academic problems, I Faced their serious, nonsense and funny questions ',
           img:images_about["2.png"]
       },
       {
           title:'50+ SALES SEMINAR PRESENTATION',
           inShort:'I joined YoursOwn startup as a beginner, so for the starting four months, I did sales, marketing and designing almost everything to attract new customer and to retain our existing users. I did sales presentation for Yo App in 50+ colleges in Delhi NCR.',
           img:images_about["3.png"]
       }
   ]
}