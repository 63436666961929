import React from 'react';
import avatarImg from './img/2.png';
import landingImg from './img/1.png';
const SOCIAL_ICONS = [
    {
        icon:"fab fa-facebook-f",
        location:'https://www.facebook.com/AyvanshiGraphicdesigner'

},
{
    icon:"fab fa-dribbble",
    location:'https://dribbble.com/akkuvanshi'
},{
    icon:"fab fa-instagram",
    location:'https://www.instagram.com/aakankshayaduvanshi/'
 },
{
    icon:"fab fa-pinterest-p",
     location:'https://in.pinterest.com/aakankshayaduvanshi/_creat'
},
{icon:"fab fa-linkedin-in",
location:'https://www.linkedin.com/in/aakanksha-yaduvanshi-01040a104/'}
 
]
const Intro = () => {
    const renderIcons = SOCIAL_ICONS.map(
        (social,id) => {
               return (
               <a href = {social.location} target="_blank" rel='noopener noreferrer' key={ id }><i  className = {social.icon} />
               </a>);
    }) 
  return (
    <section className='home' id='a'>
    <div className='container'>
        <div className='home-info'>
             <figure>
                 <img className='author-img' src={avatarImg} alt='Akanksha Graphic Designer' />
              <figcaption className='details'>
                 <h3 className='mt-12'>Akanksha Yaduvanshi</h3>
                 <h3>Graphic Designer | Illustrator | Video Editor  </h3>
              </figcaption>
             </figure>
             <div className='hom-content'>
                 <h1 className='mt-18'>
                 Create and design impressive illustrations & Graphic designs for websites, apps & for social media content
                 </h1>
             </div>
             {/* social icons */}
             <div className='social-icons'>
              <h3>Find me on</h3>
                 { renderIcons }
             </div>
        </div>
        <div className='flex-center'>
           <img className='home-img' src={landingImg} alt='Landing page pic' />
        </div>
    </div>
 </section>
  );
};

export default Intro;
