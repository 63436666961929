import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../App/Header';
import Footer from '../components/footer/footer';

const Layout = () => {
  return (
    <>
     <Header />
     <main>
        <Outlet />
     </main>
     <Footer />
    </>);
};

export default Layout;
