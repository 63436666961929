import React from 'react'

const Details = ({data}) => {
    const {title, inShort, img} = data;
  return (
    <div className='about-card'>
           <div className='info'>
               <h3>{ title }</h3>
               <p>{ inShort }</p>
           </div>
           <div className='about-details-img'>
               <img src = { img } alt='' />
           </div>
    </div>
  )
}

export default Details