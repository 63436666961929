import React from 'react';

const Footer = () => {
    const year = new Date().getFullYear()
  return (
     <footer className='footer container'>
         <div className='footer-img'>
            <img src='./assets/img/akanksha-graphic-designer2-45x44.png' alt='akanksha graphic logo designer' />
           <h3 className='mt-12'>Akanksha</h3>
         </div>
         <p className='copyright'>&copy; { year } All rights reserved by Akanksha </p>
     </footer>);
};

export default Footer;
