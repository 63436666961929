import React from 'react';
import { PORTFOLIO } from '../../data';
import PortCard from './PortCard';

const Portfolio = () => {
  const { portfolio } = PORTFOLIO;
  const renderPortfolio = portfolio.map(
    (data, id) => {
           return(
              <PortCard key={ id } data = { data }>
                { data.Children }
              </PortCard>
           );
  })
  return (
      <section className='portfolio'>
           <span className=''>VISIT MY PORTFOLIO AND KEEP YOUR FEEDBACK</span>
           <h2 className='title'>My Portfolio</h2>
           <div className='container'>
                { renderPortfolio }
           </div>
      </section>
  );
};

export default Portfolio;
