import React, { memo,useState } from 'react';
import FeatureCard from './FeatureCard';
import { WHAT_I_DO } from '../../data';
const Feature = () => {
  const [transition, setTransition] = useState('')
  const onClickTransition = (id) =>{
 setTransition(id)
      }
  const { facilities } = WHAT_I_DO;
 const renderFeatureCard = facilities.map(
   (facility, id) => {
     return(
       <FeatureCard key={id} data = { facility } transition={transition} onClickTransition = {onClickTransition} />
     )
   });
  return (
    <section className='what-I-can-do' id='contact'>
    <div className='container'>
       <span>Features</span>
       <h2 className='f-60 mt-12'>What I Do</h2>
    
    <main className='features'>
        { renderFeatureCard }
    </main>
    </div>
    </section>);
};

export default memo(Feature);
