import React from 'react';
import { Route,Routes } from 'react-router-dom';
const NotFound = () => {
  return <div>
  <Routes>
  <Route path="*" element={<li>this page is not exist please go back to our site <a href='https://vectorlo.com'>vectorlo</a></li>} />
  </Routes>
     
  </div>;
};

export default NotFound;
