import React from 'react'
import Work from '../../pages/Work';
import PortCard from '../home/PortCard';
const LoginSign_card = [
        {   
            id:'login1',
            where:'FIGMA',
            title:'Mobile App Login Signup UI Design For Weather App',
            overview:`Recently just created a weather app UI, The design of the user interface for a weather application. Hope you will like it.`,
            link:"/",
            imgURL:'https://cdn.dribbble.com/users/5166438/screenshots/16529713/media/7b7741f7dc479fdc0a70c6ed5dc17694.png?compress=1&resize=1200x900&vertical=top',
            location:'https://dribbble.com/shots/16529713-WEATHER-APP-UI-DESIGN-FIGMA-DESIGN'

        },
        {   
         id:'login2'   ,
         where:'FIGMA',
         title:'Glassmorphism Fingerprint Login Signup UI Design',
          overview:`Glassmorphism UI is based on three important effects: transparency (frosted-glass), vivid or pastel colors, and a light border.`,
         link:"/",
         imgURL:'https://cdn.dribbble.com/users/5166438/screenshots/16508112/media/99472bea21343491bc98b5c59976ff0c.png?compress=1&resize=1200x900&vertical=top',
         location:'https://dribbble.com/shots/16508112-Glassmorphism-login-form-UI-Design-Figma-Design'
     },
     {   
         id:'login3',
         where:'FIGMA',
         title:'Login Signup Screen Gradient Design',
         overview:`Minimal Login & Register Screen minimal gradients. Login form page template set on gradient background for app development.`,
         link:"/",
             imgURL:'https://cdn.dribbble.com/users/5166438/screenshots/16474570/media/cbe6864ba697e093045c8de7d65631dd.png?compress=1&resize=1200x900&vertical=top',
             location:'https://dribbble.com/shots/16474570-Login-Screen-Gradient-Design-Figma-Design'

     },
     {   
        id:'login4',
         where:'FIGMA',
         title:'Redesigning an epay Signup Form | by Akanksha',
         overview:"These signup form designs help me to learn how they are still valuable for generating new ideas for UX UI Design.",
         link:"/",
         
          imgURL:'https://cdn.dribbble.com/users/5166438/screenshots/16555787/media/2fdbde22beb9896f386859e008453f2e.png?compress=1&resize=800x600&vertical=top',
          location:'https://dribbble.com/shots/16555787-ePay-Sign-Up-Form-Re-Design-Sign-up-Form-Design-Figma-Design'

     },
     {   
        id:'login5',
         where:'FIGMA',
         title:'Mobile App Login Signup UI Design For Fitness App',
         overview:"Fitness Login App Screen ui design ux app ui login screen bodybuilding gym app design gym designed by Akanksha Graphic Designer.",
         link:"/",
          imgURL:'https://cdn.dribbble.com/users/5166438/screenshots/16401034/media/fdc3281ce0b13e57fd83026d4de7359b.png?compress=1&resize=1200x900&vertical=top',
          location:'https://dribbble.com/shots/16401034-Fitness-App-login-UI-Design'

     },
     {   
        id:'login6',   
      where:'FIGMA',
      title:'Mobile App Login Signup UI Design For Music App',
      overview:"Music APP login page designed by Akanksha Graphic Designer. Redesign  music player spotify music brand web design ux.",
      link:"/",
       imgURL:'https://cdn.dribbble.com/users/5166438/screenshots/16408858/media/60a75b4dcb33b8450e91b2078fa6acda.png?compress=1&resize=1200x900&vertical=top',
       location:'https://dribbble.com/shots/16408858-Music-APP-login-page-design'

  },
  {   
    id:'login7',   
  where:'FIGMA',
  title:'Dark blue login signup theme UX UI Design',
  overview:"When it comes to a good login page design, you want to make it as easy for your users as possible to gain access to product or service.",
  link:"/",
   imgURL:'https://cdn.dribbble.com/users/5166438/screenshots/16448773/media/f6c1ab78fad4bf21639a4350eafdf022.png?compress=1&resize=1200x900&vertical=top',
   location:'https://dribbble.com/shots/16448773-Mobile-Login-Sign-up-UI'

},
{   
    id:'login8',   
  where:'FIGMA',
  title:'Mobile App Login Signup UI Design For Pet App',
  overview:"Pet App – Login | Animation concept designed by Akanksha Graphic Designer with 2d flat character illustrations.",
  link:"/",
   imgURL:'https://cdn.dribbble.com/users/5166438/screenshots/16427331/media/8e62f37aebc2227063800977c6d4d0a0.png?compress=1&resize=1200x900&vertical=top',
   location:'https://dribbble.com/shots/16427331-Pet-App-Login-Page-UI-with-Illustration-concept'

},
{   
    id:'login9',   
  where:'FIGMA',
  title:'Carpooling App | UI Design, UX Design, Product Design',
  overview:"Carpooling is an app that matches vehicle owners and riders for a safe, enjoyable and fun carpooling experience.",
  link:"/",
   imgURL:'https://cdn.dribbble.com/users/5166438/screenshots/17605818/media/ebfacc64e71236cbb76693f52d274c88.png?compress=1&resize=1200x900&vertical=top',
   location:'https://dribbble.com/shots/17605818-Carpooling-App-UI-Design'

},
  
 ]

const LoginSign = () => {
    const renderCard  = LoginSign_card.map(
        (data) => {
  return (
    <PortCard key={data.id} data = { data}>
          <a target ="_blank" rel="noreferrer" href={data.location}><img src={data.imgURL}alt='' /></a>
  </PortCard>)
        })
  return (
    <Work>
       <header className='login-sign__header'>
         <h2 className='login-sign__title'>Mobile App Login Signup Page Design</h2>
         <p className='splash-title'>Creating successive Login Signup Form UI Designs with Figma tool. In the last few years, new design tools emerging. One of them is my favourite one i.e. Figma, which sooner seem to be a leader in the design. Mobile App Login Signup Form designed on Figma by Akanksha.</p>
       </header>
       <div className='login_sign'>
       
    { renderCard }
    </div>
    </Work>
   
  )
}

export default LoginSign;