import React from 'react';
import Feature from '../components/home/Feature';
import Intro from '../components/home/Intro';
import Portfolio from '../components/home/Portfolio';

const Home = () => {
  return (
    <>
      <Intro />
      <Feature />
      <Portfolio />
    </>);
};

export default Home;
