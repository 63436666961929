import React, { memo, useEffect, useRef, useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import FormPortal from "../components/FormPortal";
import LoginSign from "../components/my-work/LoginSign";
import ScrollToTop from "../components/ScrollToTop";
import About from "../pages/About";
import Contact from "../pages/Contact";
import Home from "../pages/Home";
import Layout from "../pages/Layout";
import NotFound from "../pages/NotFound";
import SplashScreen from "../pages/SplashScreen";
// here App component is
const App = () => {
  const [show, setShow] = useState(false);
  const portalNode = document.getElementById("model");
  const portalRef = useRef();
  // following useEffect is use for first time "CONTACT FROM POP UP"
  useEffect(()=>{
    setTimeout(()=>setShow(true),4000);
    return ()=>{
      clearTimeout(()=>setShow(true),4000);
    }
  },[])
    // following useEffect is use for  "CONTACT FROM POP UP" when massage will be btn clicked
  useEffect(()=>{
  
  const hideUpPortal = () => {
 portalNode.style.top = "80vh";
 portalNode.style.visibility = "hidden";
}
  if(show === true){
    portalNode.style.top = "";
    portalNode.style.visibility = "visible";
    portalRef.current.style.visibility = "visible";
    portalRef.current.style.opacity = "1";
    
    
  }else{
    portalRef.current.style.visibility = "hidden";
    portalRef.current.style.opacity = "0";
    hideUpPortal();
  }
  
  },[show,portalNode])
  
  
  return (
    <BrowserRouter>
    
    <FormPortal children = { <>
    <div className ="model-btn" >
    <span className = {  show === false?"show":"hide" } onClick = {()=>{setShow(!show)}}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M284.046,224.8a34.114,34.114,0,1,0,34.317,34.113A34.217,34.217,0,0,0,284.046,224.8Zm-110.45,0a34.114,34.114,0,1,0,34.317,34.113A34.217,34.217,0,0,0,173.6,224.8Zm220.923,0a34.114,34.114,0,1,0,34.317,34.113A34.215,34.215,0,0,0,394.519,224.8Zm153.807-55.319c-15.535-24.172-37.31-45.57-64.681-63.618-52.886-34.817-122.374-54-195.666-54a405.975,405.975,0,0,0-72.032,6.357,238.524,238.524,0,0,0-49.51-36.588C99.684-11.7,40.859.711,11.135,11.421A14.291,14.291,0,0,0,5.58,34.782C26.542,56.458,61.222,99.3,52.7,138.252c-33.142,33.9-51.112,74.776-51.112,117.337,0,43.372,17.97,84.248,51.112,118.148,8.526,38.956-26.154,81.816-47.116,103.491a14.284,14.284,0,0,0,5.555,23.34c29.724,10.709,88.549,23.147,155.324-10.2a238.679,238.679,0,0,0,49.51-36.589A405.972,405.972,0,0,0,288,460.14c73.313,0,142.8-19.159,195.667-53.975,27.371-18.049,49.145-39.426,64.679-63.619,17.309-26.923,26.07-55.916,26.07-86.125C574.394,225.4,565.634,196.43,548.326,169.485ZM284.987,409.9a345.65,345.65,0,0,1-89.446-11.5l-20.129,19.393a184.366,184.366,0,0,1-37.138,27.585,145.767,145.767,0,0,1-52.522,14.87c.983-1.771,1.881-3.563,2.842-5.356q30.258-55.68,16.325-100.078c-32.992-25.962-52.778-59.2-52.778-95.4,0-83.1,104.254-150.469,232.846-150.469s232.867,67.373,232.867,150.469C517.854,342.525,413.6,409.9,284.987,409.9Z"/></svg>  
    </span>
    <span className= { show === true?"show":"hide"} onClick = {()=>setShow(!show)}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"/></svg>
    </span> 
      </div>
      <div className="portal-container" ref={portalRef}>
        <Contact />
      </div>
    </> } />
    <ScrollToTop/>
      <Routes>
        <Route path='/' element = { <Layout hello = {"heall"} /> } >
            <Route index element = { <Home /> } />
            <Route path = 'akanksha-graphic-designer' element = { <About /> } />
           <Route path="Onboarding-Splash-Screen-for-Mobile-App" element = { <SplashScreen /> } />
           <Route path="mobile-app-login-signup-page-design" element = { <LoginSign /> } />
          <Route path="contact"  element = { <Contact /> } />
        </Route>      
        <Route path="*" element={<NotFound />} />
      </Routes>        
      
  </BrowserRouter>
  );
}

export default memo(App);
